import styled from "@emotion/styled"
import { colors } from "../../css/colors"

export const BaseLabel = styled.label`
    font-size: 1rem;
    margin: 0rem 0 0.3rem;
    font-weight: normal;
    color: ${colors.darkText};
`

export const BaseInput = styled.input`
    border: 0.06rem solid ${colors.darkText};
    border-radius: 0.25rem;
    padding: 0.4rem;
    font-size: 1rem;
`
export const BaseTextArea = styled.textarea`
    border: 0.06rem solid ${colors.darkText};
    border-radius: 0.25rem;
    padding: 0.4rem;
    font-size: 1rem;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: 8rem;
`
