import React, { useState } from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { SectionTitle } from "../components/SectionTitle"
import { StickyImage } from "../components/stickyImage"
import { OrangeButton } from "../components/buttons"
import { BaseInput, BaseLabel, BaseTextArea } from "../app/components/BaseFields"
import { LoginRow } from "../app/auth/components/LoginRow"
import { LoginCard } from "../app/auth/components/LoginCard"
import { colors } from "../css/colors"
import ky from "ky"
import { isFrontDev } from "../isFrontDev"

const Contact = () => {
    const [data, setData] = useState({
        fullName: "",
        email: "",
        object: "",
        message: ""
    })

    const [successful, setSuccessful] = useState(false)

    const [disabledButton, setDisabledButton] = useState(false)

    const handleChange = (event) => {
        let inputName = event.target.name
        let inputValue = event.target.value
        setData((prevData) => ({
            ...prevData,
            [inputName]: inputValue
        }))
    }

    const submit = async (event) => {
        event.preventDefault()

        const $form = event.target

        const url = isFrontDev ? "https://us-central1-teachingboost-test.cloudfunctions.net/contactUs" : "https://us-central1-teachingboost-dev.cloudfunctions.net/contactUs"

        ky.post(url, {
            json: data
        })

        setSuccessful(true)
        setDisabledButton(true)

        //The confirmations fades out in 3 secs
        setTimeout(() => {
            setSuccessful(false)
            setDisabledButton(false)
        }, 3000)

        //Empty the form
        $form.reset()
    }

    return (
        <Layout>
            <StickyImage />
            <MainComponent>
                <SectionTitle title="CONTACT" />
                <LoginCard>
                    <form id="messageForm" onSubmit={submit}>
                        <Success show={successful}>Email envoyé !</Success>

                        <LoginRow>
                            <BaseLabel htmlFor="fullName">Nom & Prénom</BaseLabel>
                            <BaseInput id="fullName" type="text" name="fullName" placeholder="Nom & Prénom" onChange={handleChange} required />
                        </LoginRow>

                        <LoginRow>
                            <BaseLabel htmlFor="email">Email :</BaseLabel>
                            <BaseInput id="email" type="email" name="email" placeholder="Email" onChange={handleChange} required />
                        </LoginRow>

                        <LoginRow>
                            <BaseLabel htmlFor="object">Objet :</BaseLabel>
                            <BaseInput id="object" type="text" name="object" placeholder="Objet" onChange={handleChange} required />
                        </LoginRow>

                        <BaseTextArea rows="4" cols="50" name="message" form="messageForm" placeholder="Tapez votre message ici..." onChange={handleChange} required />

                        <LoginRow>
                            <input type="checkbox" name="copy" id="copy" checked />
                            <BaseLabel htmlFor="copy">Copie par mail</BaseLabel>
                        </LoginRow>

                        <OrangeButton disabled={disabledButton}>ENVOYER</OrangeButton>
                    </form>
                </LoginCard>
            </MainComponent>
        </Layout>
    )
}

export default Contact

const MainComponent = styled.div`
    margin: 2rem 1.5rem;
`

const Success = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
    background-color: ${colors.success};
    text-align: center;
    color: ${colors.lightText};
    font-weight: bold;
    margin: 1rem 0;
    border-radius: 5px;
    padding: 0.5rem;
`
