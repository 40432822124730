import styled from "@emotion/styled"

export const LoginCard = styled.section`
    border: 0.06rem solid black;
    border-radius: 0.25rem;
    box-sizing: border-box;
    width: 30rem;
    max-width: 80%;
    margin: 2rem auto;
    background-color: white;
    padding: 1rem;
`

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 20rem;
    max-width: 100%;
    margin: 0 auto;
`
